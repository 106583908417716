import React from 'react'
import { Config, Routes } from '@constants'
import Button from '@atoms/Button'
import logo from '@static/icereach-text.svg'

const SideTrial = () => {
  return (
    <div className="section-trial">
      <div className="section-sticky bg-primary-backgroundLight space-y-2 py-5 px-4 rounded-lg flex flex-col justify-center text-center">
        <img
          className="hidden md:block h-4 w-40 mb-2"
          src={logo}
          alt={Config.LOGO_TITLE}
          title={Config.LOGO_TITLE}
        />
        <div className="text-xl md:text-base font-bold leading-snug md:leading-relaxed pb-2 md:pb-0">
          Reach 100+ leads a week on autopilot
        </div>
        <div className="text-xs md:text-mini text-gray-600 leading-relaxed pb-2">
          Launch your first LinkedIn lead generation campaign in 5 minutes.
        </div>

        <a
          href={Routes.APP.USER.SIGN_UP}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button type="submit" className="w-full">
            Try For Free
          </Button>
        </a>
      </div>
    </div>
  )
}

export default SideTrial
